.mainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  //background-color: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
  position: fixed;
  z-index: 2;
  top: 0;
  padding-inline: 10%;
  //mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
}

/*MAIN LOGO*/

.logoLink {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: fit-content;
  gap: 10px;
  cursor: pointer;
  z-index: 5;
}
.logoText {
  color: rgba(225, 225, 225, 0.95);
}
.mainLogo {
  height: 50px;
}

/*HAMBURGER BUTTON*/

.hamburger-button {
  display: flex;
  flex-direction: column;
  width: 60px;
  gap: 10px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin-right: 10%;
  transition: all 0.3s ease-in-out;
  z-index: 3;
  aspect-ratio: 1;
}
.hamburger-button.open {
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 10%;
  }
}
.line {
  width: 100%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease-in-out;
}

/*HAMBURGER LINES ANIMATION .open*/

.hamburger-button.open .line:nth-child(1) {
  transform: rotate(45deg) translateY(10px) translateX(8px);
}
.hamburger-button.open .line:nth-child(2) {
  opacity: 0;
}
.hamburger-button.open .line:nth-child(3) {
  transform: rotate(-45deg) translateY(-10px) translateX(8px);
}

/*SIDENAV*/

.menu {
  width: 300px;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  height: 100vh;
  position: fixed;
  transform: translateX(100%);
  transition: all 0.3s ease;
  background-color: #171a21;
}
.loggInnBtn {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
  @media (max-width: 768px) {
    margin-right: 10%;
  }
}
.menu li {
  margin-right: 20px;
  transform: translateX(100%);
  transition: transform 0.5s ease;
  @media (max-width: 768px) {
    margin-right: 10%;
  }
}
.menu ul {
  list-style: none;
  padding-left: 0;
  margin-top: 90px;
  height: 100%;
  text-align: right;
  border-top: 2px solid rgba(255, 255, 255, 0.9);
  border-bottom: 2px solid rgba(255, 255, 255, 0.9);
}
.navLink {
  display: block;
  padding: 10px;
  text-decoration: none;
  transition: all 0.2s;
  letter-spacing: 0px;
}
.navLink:hover {
  letter-spacing: 5px;
  transition: all 0.2s;
}

/*SIDENAV OPEN*/

.menu.open {
  transform: translateX(0);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.menu.open li {
  transform: translateX(0%);
}
