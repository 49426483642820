.heroContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: relative;
    box-shadow: 0px 30px 100px 100px rgba(22, 26, 34, 1);
    @media (max-width: 1919px) {
        box-shadow: none;
    }
}

.badge-container {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 60px;
    gap: 20px;
    margin-bottom: 25px;
    bottom: 0;
    right: 0;
    left: 0;
    padding-left: 10%;
    padding-right: 10%;
}

.badge {
    height: 100%;
}

.mainCta {
    padding-left: 10%;
    padding-right: 10%;
    max-width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgba(255, 255, 255, 0.9);
    gap: 20px;
    opacity: 0;
    visibility: hidden;
    animation: mainCtaFadeIn 2.7s ease 2.7s forwards; /* 2s duration, 3s delay, 'forwards' keeps the final state */
    @media (max-width: 1200px) {
        max-width: 100%;
    }
}

@keyframes mainCtaFadeIn {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}

.hero-image {
    width: 100%;
    height: auto;
    bottom: 0;
    object-fit: contain;
    position: absolute;
    mask-image: linear-gradient(to bottom, black 0% 90%, transparent 100% 100%);
    filter: brightness(0.7);
    @media (max-width: 500px) {
        width: auto;
        height: 100vh;
    }
}

.heroVideo {
    height: 100vh;
    object-fit: cover;
    width: 100%;
    object-position: right center;
    position: absolute;
    z-index: -1;

    mask-image: linear-gradient(to bottom, black 0% 90%, transparent 100% 100%);

    /*@media (max-width: 1919px) {
        display: none;
    }*/
}
