/*PRIMARY BUTTON*/


.primary-button {
    display: inline-block;
    background-color: transparent;
    padding: 0.8em 1.3em 0.7em;
    border: 2px solid #fbb104;
    border-radius: 10.2px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    outline: none;
}

.primary-button-text {
    color: #171a21;
    position: relative;
    z-index: 1;
    transition: color 0.2s;
}

.primary-button::before {
    content: "";
    position: absolute;
    display: block;
    width: 130%;
    height: 102%;
    background-color: #fbb104;
    left: 0;
    top: 0;
    transform: skew(-33deg, 0) translate(-10%);
    transition: transform 300ms;
}

.primary-button:hover::before {
    transform: skew(-33deg, 0) translate(110%);
}

.primary-button:hover span {
    color: #fbb104;
}

/*SECONDARY BUTTON*/

.secondary-button {
    display: inline-block;
    background-color: transparent;
    padding: 0.8em 1.3em 0.7em;
    border: 2px solid white;
    border-radius: 10.2px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    
}

.secondary-button-text {
    color: white;
    position: relative;
    z-index: 1;
    transition: color 0.2s;
    white-space: nowrap;
}

.secondary-button::before {
    content: "";
    position: absolute;
    display: block;
    width: 130%;
    height: 102%;
    background-color: white;
    left: 0;
    top: 0;
    transform: skew(-33deg, 0) translate(-110%);
    transition: transform 300ms;
}

.secondary-button:hover::before {
    transform: skew(-33deg, 0) translate(-10%);
}

.secondary-button:hover span {
    color: #171a21;
}
