@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/metropolis-2");

$base-font-size: 1rem; // 16px if 1rem = 16px

:root {
    --font-scale: 1.2; // Default scale
}

@media (max-width: 600px) {
    :root {
        --font-scale: 1; // Adjust for smaller screens
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
    :root {
        --font-scale: 1.1; // Adjust for medium screens
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    :root {
        --font-scale: 1.2; // Adjust for medium screens
    }
}

@media (min-width: 1441px) and (max-width: 2024px) {
    :root {
        --font-scale: 1.3; // Adjust for medium screens
    }
}

@media (min-width: 2025px) and (max-width: 2560px) {
    :root {
        --font-scale: 1.4; // Adjust for medium screens
    }
}

html {
    font-size: $base-font-size;
}

h1 {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1.375rem * var(--font-scale) * 1.3);
}

h2 {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1.375rem * var(--font-scale));
}

h3 {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1.125rem * var(--font-scale));
}

h4 {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1rem * var(--font-scale)); // Slightly larger than the base size
}

h5 {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(0.875rem * var(--font-scale)); // Close to the base size
}

h6 {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(0.75rem * var(--font-scale)); // Smallest headline
}

p {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1rem * var(--font-scale));
    line-height: 1.6;
}

body {
    font-family: "Poppins", sans-serif;
}

html {
    font-size: $base-font-size;
}

/*BUTTONS*/

.primary-button-text,
.secondary-button-text {
    font-family: "Metropolis", sans-serif;
    font-weight: 700;
    font-size: 20px;

    @media (min-width: 992px) {
        font-size: 20px;
    }
    @media (max-width: 768px) {
        font-size: 18px;
    }
    @media (max-width: 576px) {
        font-size: 16px;
    }
}

/*-----------HEADER-----------*/

.logoText {
    color: rgba(255, 255, 255, 0.95);
    font-family: "Metropolis", sans-serif;
    font-weight: 600;
    font-size: calc(1rem * var(--font-scale) * 1.3);
}
.navLink {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1.7rem * var(--font-scale));
}

/*-----------HERO-----------*/

.hero-cta-heading {
    font-size: calc(2rem * var(--font-scale));
}
.hero-cta-paragraph {
    font-size: calc(1rem * var(--font-scale));
}

/*-----------FEATURES-----------*/

.feature-description-title {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1.3rem * var(--font-scale));
}
.featureBtn {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(0.8rem * var(--font-scale)); // Base font size
}

/*-----------PRICES-----------*/

.price {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(2.5rem * var(--font-scale));
}

.price-title {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1.3rem * var(--font-scale));
}

.price-title-section {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1.3rem * var(--font-scale));
}

/*-----------CONTACTFORM-----------*/

/*-----------FOOTER-----------*/

/*-----------PERSONVERN-----------*/
.personvern-title {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1.2rem * var(--font-scale));
}
.personvern-subtitle {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1.2rem * var(--font-scale));
}
.personvern-ul {
    color: rgba(255, 255, 255, 0.95);
    font-size: calc(1rem * var(--font-scale));
}


