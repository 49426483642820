.prices-container {
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    padding-right: 10%;
    gap: 20px;
    width: auto;
    @media (max-width: 1000px) {
        flex-direction: column;
    }
}

.price-info {
    display: flex;
    flex-direction: column;
    background-color: #171a21;
    border-radius: 10px;
    padding: 30px;
    gap: 20px;
    width: 100%;
    @media (max-width: 1000px) {
        padding: 20px;
        width: auto;
    }
}

.price-primary {
    display: flex;
    flex-direction: column;

    background-color: rgba(0, 0, 0, 0.2);
    padding: 30px;
    border-radius: 10px;
    gap: 20px;
    position: relative;
    width: 100%;
    @media (max-width: 1000px) {
        padding: 20px;
        width: auto;
    }
}

.price-secondary {
    display: none;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    gap: 20px;
    position: relative;
    padding: 30px;
    width: 100%;
    @media (max-width: 1000px) {
        padding: 20px;
        width: auto;
    }
}

.price-paragraph {
    display: flex;
    align-self: center;
}

.price-title {
    display: flex;
    align-self: center;
}

.price {
    display: flex;
    align-self: center;
}

.prices-button-container-gradient {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
}
