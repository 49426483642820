/*-----------DEFAULTS-----------*/
:root {
    --main: rgba(82, 126, 146, 0.15);
    --background: rgba(255, 255, 255, 0.8);
    --secondBackground: rgba(255, 255, 255, 0.6);
    --background-break: #ffffff0d;
    --contrastColor: #ffa800;
    --thirdColor: #527e92;
    --fourthColor: #48b0c5;
    --background-color: linear-gradient(#171a21e3, #171a2194);
    //#171a21e3;//#171a21b3;

    --default-padding: 40px;
    --default-gap: 20px;

    @media (max-width: 1400px) {
        --default-gap: 40px;
    }

    --big-gap: 40px;
    --small-gap: 10px;
    --mobile-padding: 20px;
}

.default-wrapper {
    padding-top: 80px;
    position: relative;
    background: #171923;
    min-height: 100vh;
    box-sizing: border-box;
}

.pagination-dots {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 10px;
    position: absolute;
    margin-bottom: 20px;
    @media (min-width: 1200px) {
        display: none;
    }
}

.dot {
    background-color: rgba(255, 255, 255, 0.2);
    width: 10px;
    height: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 100%;
    top: 0;
}

.dot-active {
    background-color: rgb(255, 255, 255, 0.9);
    width: 15px;
    height: 15px;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
}

.not-on-mobile {
    @media (max-width: 768px) {
        display: none;
    }
}

#root {
    font-family: "Poppins", sans-serif;
}
.no-mouse-event {
    pointer-events: none;
}
html {
    scroll-behavior: smooth;
}
* {
    margin: 0;
    box-sizing: border-box;
}

/*default container layout*/

.container {
    padding: var(--default-padding);
    gap: 20px;
    border-radius: 10px;
    @media (max-width: 768px) {
        padding: 20px;
    }
}

/*default divider*/

hr {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.9);
}

/*default flexboxes*/

.flexbox-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: auto;
    width: auto;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.flexbox-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    width: auto;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.no-gap {
    gap: 0px;
}

.center-column {
    align-items: center;
}

.center-row {
    justify-content: center;
}

/*-----------BODY-----------*/

.app {
    position: relative;
    gap: calc(50px + 5vw);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

/*-----------BACKGROUND-----------*/

.background-container {
    position: absolute;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -5;
}

.body-image {
    filter: brightness(60%);
    height: 100%;
    width: 100%;
    //position: fixed; // This keeps the image in place, acting as a background
    top: 0;
    left: 0;
    z-index: -4; // Keep it below all other elements
    background-repeat: repeat;
}
.blur {
    /*
    backdrop-filter: blur(100px);
    width: 100%;
    height: 100%;
    position: fixed; // This keeps the blur in place, acting as a fixed overlay
    top: 0;
    left: 0;
    z-index: -2; // Keep it above the image but below content*/

    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--background-color);
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
}

.beta-tag {
    font-size: 14px;
    color: var(--contrastColor);
    background: var(--background-break);
    padding: 5px;
    border-radius: 10px;

    & > p {
        font-size: 14px;
        color: var(--contrastColor);
    }
}


/* Hide Items on Mobile */

.onPc {
    @media (max-width: 768px) {
        display: none;
    }
}