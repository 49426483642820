.time-line-container {
    display: flex;
    flex-direction: row;
    gap: 0px;
    //width: 100vw;
    height: 100%;
    overflow-x: scroll;
    padding-block: 40px;
    padding-top: 20px;
}

.time-line-scroll {
    /*max-height: 100vh;
    max-width: 50%;
    */
    max-width: 100vw;
    width: 100vw;
    min-width: 50vw;

    .icon-button-container {
        width: 30%;
    }
}

.time-line-line {
    width: 100%;
    height: 8px;
    background-color: var(--main);
    position: relative;
}

.time-line-line-inner {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    width: 0%;
    height: 5px;
    background-color: var(--contrastColor);
    border-radius: 10px;
    //transition: all 200s linear;

    &::after {
        content: "";
        position: absolute;

        top: -50%;
        right: 0px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--contrastColor);
        z-index: 2;

        background-image: #fbb106;
        background-size: 200% auto;
        animation: rotatingGradient 5s linear alternate infinite;
    }
}

@keyframes time-line-line-inner {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.time-line-under {
    @media (min-width: 750px) {
        margin-left: 350px
    }
}

.time-line-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--default-padding);
    width: 700px;
    height: 40vh;

    .icon-button-container {
        animation: change-background 1s forwards linear;//, scale-up-and-down 2s forwards linear;
        animation-timeline: view(x);
        animation-range: 55% 60%;

        aspect-ratio: 1.4/1;

        display: flex !important;
        padding-inline: 10px;
        transform-origin: bottom;
        max-height: 70%;
        max-width: 80vw;

        @media (max-width: 1000px) {
            aspect-ratio: 0;
            padding: 10px;
        }
    }
}

@keyframes scale-up-and-down {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes change-background {
    to {
        background-color: #7f67300d;
    }
}

.time-line-line-down,
.time-line-line-up {
    width: 8px;
    height: 4em;
    background-color: var(--main);
    position: relative;
}

.time-line-line-up {
    border-radius: 0 0 10px 10px;
}

.time-line-line-down {
    border-radius: 10px 10px 0 0;
}

.time-line-line-down-inner,
.time-line-line-up-inner {
    width: 50%;
    height: 0%;

    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    background-color: var(--contrastColor);
}

.time-line-line-up-inner {
    top: -0px;

    &::after {
        content: "";
        position: absolute;

        top: -20px;
        left: -12px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--contrastColor);
        z-index: 2;

        background-image: #fbb106;
        background-size: 200% auto;
        animation: rotatingGradient 5s linear alternate infinite;
    }
}

.time-line-line-down-inner {
    bottom: -0px;

    &::after {
        content: "";
        position: absolute;

        bottom: -25px;
        left: -13px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--contrastColor);
        z-index: 2;

        background-image: #fbb106;
        background-size: 200% auto;
        animation: rotatingGradient 5s linear alternate infinite;

        @media (max-width: 1000px) {
            bottom: -20px;
        }
    }
}

.time-line-line-down-inner {
    animation: time-line-line-down-inner 1s forwards linear;
    animation-timeline: view(x);
    animation-range: 45% 55%;
    border-radius: 10px 10px 0 0;
}

.time-line-line-up-inner {
    animation: time-line-line-up-inner 1s forwards linear;
    animation-timeline: view(x);
    animation-range: 50% 55%;
    border-radius: 0 0 10px 10px;
}

@keyframes time-line-line-down-inner {
    from {
        height: 0;
    }
    to {
        height: 100%;
    }
}

@keyframes time-line-line-up-inner {
    from {
        height: 0;
    }
    to {
        height: 100%;
    }
}

@keyframes rotatingGradient {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.rotating-gradient {
    background-image: linear-gradient(to right, #fbb106, #e13813, #ec483e);
    background-size: 200% auto;
    animation: rotatingGradient 5s linear alternate infinite;
}
