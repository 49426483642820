.legalContainer {
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: auto;
    height: fit-content;
    margin-top: 150px;
    margin-left: 20%;
    margin-right: 20%;
    padding: 40px;
    border-radius: 10px;
    @media (max-width: 768px) {
        padding: 20px;
        margin-left: 10%;
        margin-right: 10%;
    }

    h2 {
        color: var(--contrastColor)
    }
}
