.mainFooter {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 35px;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
}

.footerBG {
    display: flex;
    position: absolute;
    bottom: 0;
    align-self: center;
    background-color: #171a21;
    width: 100%;
    height: 50%;
    z-index: -1;
}

.footerDivider {
    border: 1px solid rgba(255, 255, 255, 0.9);
}

.footerContainer1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.9);
}

.PersonvernLink {
    color: rgba(255, 255, 255, 0.9);
}

.socialIcons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: min-content;
}

.socialLink {
    margin-right: 10px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    height: 20px;
}

.contactList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style-type: none;
    padding: 0;
}

.contactList a {
    color: rgba(255, 255, 255, 0.9);
    padding: 0;
}
