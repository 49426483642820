.featuresContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    padding-right: 10%;
    padding-left: 10%;
    @media (max-width: 1200px) {
        flex-direction: column-reverse;
    }
}

.phoneCanvas {
    display: flex;
    justify-content: center;
    width: 409px;
    height: 100%;
    pointer-events: none;
    @media (max-width: 1200px) {
        width: 100%;
        height: 600px;
        pointer-events: none;
    }
}

.icon-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    gap: 10px;
    position: relative;
    cursor: pointer;
    flex: 20%;
    aspect-ratio: 1;
    transition: all 0.2s ease-in;
    @media (max-width: 1200px) {
        display: none;
    }
}

.icon-button-container:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.icon-button-container-gradient {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border-radius: 10px;
    position: absolute;
}

.orange-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 3px solid rgba(250, 176, 5, 0.95);
    padding: 0.8em;
}



.iconBtn {
    color: rgba(250, 176, 5, 0.95); //ikonets størrelse justeres inline i buttonsfeat
}

.featInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    height: fit-content;
    width: auto;
    @media (max-width: 1400px) {

    }
}

.mobile-controls {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1200px) {
        display: flex;
    }
}

.feature-description-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    position: relative;
    padding: 30px;
    min-height: 240px;
    @media (max-width: 1000px) {
        padding: 20px;
    }
}

.textMorphBox {
    position: relative;
}

.textMorphBoxText {
    height: 100%;
}

.morphIn {
    animation: morphIn 0.5s ease-in-out forwards;
}

@keyframes morphIn {
    0% {
        opacity: 0;
        filter: blur(10px);
    }
    100% {
        opacity: 1;
        filter: blur(0px);
    }
}

.morphOut {
    animation: morphOut 1s ease-in-out forwards;
    position: absolute;
    top: 0;
    left: 0;
    //transform: translate(0px, -20px);
}

@keyframes morphOut {
    0% {
        opacity: 1;
        filter: blur(0px);
    }
    100% {
        opacity: 0;
        filter: blur(10px);
    }
}
