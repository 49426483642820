.contactContainer {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: relative;
}

.contactForm {
    position: relative;
    background-color: #ffffff;
}

.mapContainer {
    height: auto;
    width: 100%;
    @media (max-width: 768px) {
        display: none;
    }
}

.gm-style > div:nth-child(17) > div:nth-child(1) {
    display: none !important;
}

.gm-style > div:nth-child(15) > div:nth-child(1) > a:nth-child(1) > div:nth-child(1) > img:nth-child(1) {
    display: none !important;
}

.contactHeading {
    color: rgba(0, 0, 0, 0.9);
    width: 100%;
}


.background-cover {
    background: black;
    color: white;
    z-index: 100;
    position: absolute;
    width: 200%;
    height: 200%;
    border-radius: 100%;
    animation: slideCircleIn 0.5s ease-in-out;
    transform-origin: top right;
}

@keyframes slideCircleIn {
    0% {
        transform: scale(0)
    }
    100% {
        transform: scale(1)
    }
}

.sent-message {
    color: white;
    z-index: 200;
    position: relative;
    padding: 20px;
    animation: fadin 0.5s ease-in-out;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.relative {
    position: relative;
    width: stretch;
    height: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.hasSent {
    position: absolute;
    width: stretch;
    height: stretch;
}

//background-cover success